import React, {useState} from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout.js';
import { Document, Page } from 'react-pdf';
import useKey from "react-use/lib/useKey";
import { useWindowSize } from 'react-use';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faCircle
} from '@fortawesome/free-solid-svg-icons';


export default ({ data}) => {

  const {width} = useWindowSize();
  console.log({width})

  let script = data.markdownRemark;
  let [page, setPage] = useState(1);

  const nextPage = () => setPage(page+= 1);
  const prevPage = () => setPage(page-= 1);
  const firstPage = () => setPage(1);
  useKey('ArrowRight', nextPage);
  useKey('ArrowLeft', prevPage);

  const Navigation = () => {
    return (
      <nav className='arrow-navigation'>
        <FontAwesomeIcon icon={faLongArrowAltLeft} onClick={prevPage}/>
        <FontAwesomeIcon icon={faCircle} onClick={firstPage}/>
        <FontAwesomeIcon icon={faLongArrowAltRight} onClick={nextPage}/>
      </nav>
    );
  };

  return (
      <article className='writing-piece'>
      <header>
        <h1>{script.frontmatter.title}</h1>
      </header>
      <div className='pdf'>
        <Document
         file={{url: script.frontmatter.pdf}}
          onClick={()=> setPage(page += 1)}
        >
          <Page pageNumber={page} scale={width > 700 ? "1.5" : "0.5"} />
        </Document>
        <Navigation />
        <a href={script.frontmatter.pdf} download>download the script</a>
      </div>
        <Layout></Layout>
      </article>
  );
}

export const query = graphql`
query($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        pdf
      }
    }
  }
`

