import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';

export default ({title}) => {
  const data = useStaticQuery(
    graphql`
       query {
        site {
          siteMetadata {
            title
          }
        }
      }
   `
  )
  const sections = ['/writing', '/comedy', '/newsletter', '/contact'];
  const LinkList = ({to, name}) => (
    <li><Link to={to} activeClassName='active'>{to.replace('/','')}</Link></li>
  )


  return (
    <div className='title-bar'>
      <Link to="/"><h1>{data.site.siteMetadata.title}</h1></Link>
      <ul>
        {sections.map(section => <LinkList to={section}/>)}
      </ul>
    </div>
  )
}

