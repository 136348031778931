import React from 'react';
import TitleBar from './title-bar.js'

export default ({children, name }) => {
    return (
      <div id={name}>
        <TitleBar />
        <main>
          {children}
        </main>
      </div>
    )
}
